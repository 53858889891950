<template>
  <graph
    v-if="account"
    class="aging-timeline"
    width="100%"
    height="200px"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
export default {
  components: {
    graph: () => import('@/components/graph')
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          stacked: true,
          type: 'bar'
        },
        dataLabels: {
          enabled: false
        },
        labels: this.labels,
        stroke: {
          width: 4
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          labels: {
            formatter: y => this.formatCurrency(y, this.account.currency)
          },
          min: 0
        },
        tooltip: {
          x: {
            format: 'MMM'
          },
          y: {
            formatter: y => this.formatCurrency(y, this.account.currency)
          }
        }
      }
    },
    labels () {
      const date = this.moment(this.account.date)
      return this.account.aging.days.map(d => {
        const dd = this.moment(date)
        dd.add(d, 'days')
        return dd.valueOf()
      })
    },
    series () {
      return this.account.aging.ranges.map(r => ({
        data: r.data,
        name: r.name,
        type: 'area'
      }))
    }
  },
  props: {
    account: Object
  }
}
</script>
